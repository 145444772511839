export function GetSessionFlag(name: string) {
    const value = sessionStorage.getItem(name) ? true : false;
    return value;
};
export function ClearSessionFlag(name: string) {
    return sessionStorage.removeItem(name);
};
export function SetSessionFlag(name: string) {
    return sessionStorage.setItem(name, "1");
};

// reset stuff
export function ResetSessionFlags() {
    ClearSessionFlag("formChangesAllowNavigation");
    ClearSessionFlag("formChangesPending");
}

// fake slower bandwidth
export function IsSlowModeEnabled() {
    return GetSessionFlag("debugSlowMode");
};

export function SetFormChangesPending(value: boolean) {
    if (value === true) { 
        SetSessionFlag("formChangesPending");
    } else {
        ClearSessionFlag("formChangesPending");
    }
};

// export function SetFormChangesAllowNavigation() {
//     sessionStorage.setItem("formChangesAllowNavigation", "1");
// };
export function AreFormChangesPending() {
    const value = GetSessionFlag("formChangesPending");

    if (value) {
        const allowNavigation = GetSessionFlag("formChangesAllowNavigation");

        if (allowNavigation) {
            ClearSessionFlag("formChangesAllowNavigation");
            return false;
        }
    }

    // there is an override possible here
    return value;
};

export function ArmaReforger_SetServiceExperimental(serverid: string, value: boolean) {
    if (value) {
        SetSessionFlag(`cache:ArmaReforger:isExperimental:current`);
    } else {
        ClearSessionFlag(`cache:ArmaReforger:isExperimental:current`);
    }
};

export function ArmaReforger_IsServiceExperimental(serverid: string) {
    return GetSessionFlag(`cache:ArmaReforger:isExperimental:current`);
};

export function SetCachedData(key: string, data: any) {
    sessionStorage.setItem(`cache:${key}`, JSON.stringify(data));
    const cacheUpdatedEvent = new CustomEvent("sessionStorageCacheUpdated", {detail: {cacheKey: key}});
    document.dispatchEvent(cacheUpdatedEvent);
}
export function GetCachedData(key: string) {
    const data = sessionStorage.getItem(`cache:${key}`);

    if (!data || data === '{}') {
        return null;
    }

    let parsed = null;
    try {
        parsed = JSON.parse(data);
    } catch (error) {
        return null;
    }

    return parsed;
}


export function ArmaReforger_SetServiceDetails(data: any) {
    return SetCachedData(`ArmaReforger:serviceDetails:current`, data);
}
export function ArmaReforger_GetServiceDetails() {
    return GetCachedData(`ArmaReforger:serviceDetails:current`);
}

export function SetOwnedNodes(data: any) {
    if (!data) {
        return;
    }
    SetCachedData(`ownedNodes`, data.map((item:any) => item.name));
}
export function GetOwnedNodes() {
    const nodes = GetCachedData(`ownedNodes`);
    if (!nodes) { return new Set<string>([]); }

    return new Set<string>(nodes);
}

// export const cacheKeys = {
//     CurrentServiceDetails: `serviceDetails:current`
// }

// export function SetCurrentServiceDetails(data: any) {
//     return SetCachedData(`serviceDetails:current`, data);
// }

// export function GetServiceDetails() {
//     return GetCachedData(`serviceDetails:current`);
// }
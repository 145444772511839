import { Link as NextUILink } from "@nextui-org/react";
import { FiLink } from "react-icons/fi";

interface LinkProps {
    href: string;
    text: string;
    target?: string;
    size?: "sm" | "md" | "lg";
}

export default function Link(props: LinkProps) {
    const linkSize = props.size ?? "sm";

    return (
        <NextUILink href={props.href} size={linkSize} target={props.target}><div className="flex gap-1 flex-row p-0 m-0 items-center text-sm"><FiLink size={10} /><div>{props.text}</div></div></NextUILink>
    )
}
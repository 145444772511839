import { Input, Button, Divider, Textarea } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { FiChevronDown, FiDelete, FiEye, FiEyeOff, FiPlusCircle, FiRotateCcw, FiTrash, FiXCircle } from "react-icons/fi";

interface InputProps {
    label: string;
    value: string;
    name: string;
    disabled?: boolean;
    // callbacks
    onInputChanged?: Function;
    callbackProps?: any;
}

export interface InputFieldProps extends InputProps {
    tooltip?: string;
    uncontrolled?: boolean;
    type?: "text" | "password" | "number" | "date" | "time";
    // validation
    validationLiteral?: string;
    validationRegex?: string;
    validationHint?: string;
    isValidOverride?: boolean;
    hideHintWhenEmpty?: boolean;
}

function Validate(what: any, regex: string | undefined) {
    if (!regex) return true;

    const pattern = new RegExp(regex);
    
    return pattern.test(what);
}

export function PasswordInputFieldB(props: InputFieldProps) {
    const [valid, setValid] = useState<boolean|null>(true);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        setValid(Validate(props.value, props.validationRegex));
    }, [props.value, props.validationRegex]);

    useEffect(() => {
        if (!props.onInputChanged) {
            return;
        }

        const value = props.uncontrolled ? "" : props.value;
        const valid = Validate(value, props.validationRegex);

        props.onInputChanged({
            name: props.name,
            value: value, 
            isValid: valid
        });
    }, []);

    const hint = !props.validationHint ? "" : props.validationHint;

    function onValueChange(data: any) {
        if (!props.onInputChanged) {
            return;
        }

        if (props.type === "number") {
            data = Number(data);
        }
        
        props.onInputChanged({name: props.name, value: data, isValid: Validate(data, props.validationRegex)});
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Input 
                isDisabled={props.disabled}
                value={props.uncontrolled ? undefined : props.value}
                name={props.name}
                aria-label={props.label}
                fullWidth
                labelPlacement="outside"
                className="text-input-field"
                classNames={{ label: "text-input-label" }}
                variant="bordered"
                color={!valid ? "danger" : "success"}
                errorMessage={!valid && hint}
                validationState={valid ? "valid" : "invalid"}
                onValueChange={props.uncontrolled ? undefined : onValueChange}

                type={isVisible ? "text" : "password"}
                endContent={
                    <button className="focus:outline-none" type="button" onClick={() => setVisible(!isVisible)} aria-label="toggle password visibility">
                      {isVisible ? (
                        <FiEyeOff size={18} />
                      ) : (
                        <FiEye size={18} />
                      )}
                    </button>
                }
                />
        </div>
    )
}

export function PasswordInputField(props: InputFieldProps) {
    const [inputStatus, setInputStatus] = useState<any>({valid: true, color: "default", hint: "", visible: false});
    // const [valid, setValid] = useState<boolean|null>(true);
    // const [isVisible, setVisible] = useState(false);

    // useEffect(() => {
    //     setValid(Validate(props.value, props.validationRegex));
    // }, [props.value, props.validationRegex]);

    function ValidateInput(inputValue: string | undefined) {
        let value:any = props.uncontrolled ? "" : inputValue;
        // if (inputValue === undefined) {
        //     value = props.uncontrolled ? "" : props.value;
        // } else {
        //     value = inputValue;
        // }
        let isValid; 
        if (props.isValidOverride !== undefined) {
            isValid = props.isValidOverride;
        } else {
            if (props.validationLiteral) {
                isValid = value === props.validationLiteral;
            } else {
                isValid = Validate(value, props.validationRegex);
            }
        }

        if (props.value === "" && props.hideHintWhenEmpty) {
            setInputStatus({...inputStatus, valid: isValid, color: "default", hint: ""});
        } else {
            if (isValid) {
                setInputStatus({...inputStatus, valid: isValid, color: "success", hint: props.validationHint ?? ""});
            } else {
                setInputStatus({...inputStatus, valid: isValid, color: "default", hint: props.validationHint ?? ""});
            }
        }

        // if (props.type === "number") {
        //     value = Number(value);
        // }

        // if (props.onInputChanged) {
        //     props.onInputChanged({
        //         name: props.name,
        //         value: value, 
        //         isValid: isValid
        //     });
        // }
        return isValid;
    }

    useEffect(() => {
        ValidateInput(props.value);
    }, [props.value, props.isValidOverride, props.validationLiteral]);

    // useEffect(() => {
    //     if (!props.onInputChanged) {
    //         return;
    //     }

    //     const value = props.uncontrolled ? "" : props.value;
    //     const valid = Validate(value, props.validationRegex);

    //     props.onInputChanged({
    //         name: props.name,
    //         value: value, 
    //         isValid: valid
    //     });
    // }, []);

    // const hint = !props.validationHint ? "" : props.validationHint;

    function onValueChange(data: any) {
        if (!props.onInputChanged) {
            return;
        }

        const valid = ValidateInput(data);
        
        // props.onInputChanged({name: props.name, value: data, isValid: Validate(data, props.validationRegex)});
        props.onInputChanged({name: props.name, value: data, isValid: valid});
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Input 
                isDisabled={props.disabled}
                value={props.uncontrolled ? undefined : props.value}
                name={props.name}
                aria-label={props.label}
                fullWidth
                labelPlacement="outside"
                className="text-input-field"
                classNames={{ label: "text-input-label" }}
                variant="bordered"
                color={inputStatus.color}
                errorMessage={inputStatus.hint}
                isInvalid={inputStatus.hint && !inputStatus.valid}
                onValueChange={props.uncontrolled ? undefined : onValueChange}

                type={inputStatus.isVisible ? "text" : "password"}
                endContent={
                    <button className="focus:outline-none" type="button" onClick={() => setInputStatus({...inputStatus, isVisible: !inputStatus.isVisible})} aria-label="toggle password visibility">
                      {inputStatus.isVisible ? (
                        <FiEyeOff size={18} />
                      ) : (
                        <FiEye size={18} />
                      )}
                    </button>
                }
                />
        </div>
    )
}

export function InputFieldB(props: InputFieldProps) {
    const [valid, setValid] = useState<boolean|null>(true);
    
    useEffect(() => {
        setValid(Validate(props.value, props.validationRegex));
    }, [props.value, props.validationRegex]);

    useEffect(() => {
        if (!props.onInputChanged) {
            return;
        }

        const value = props.uncontrolled ? "" : props.value;
        const valid = Validate(value, props.validationRegex);

        props.onInputChanged({
            name: props.name,
            value: value, 
            isValid: valid
        });
    }, []);

    const hint = !props.validationHint ? "" : props.validationHint;

    function onValueChange(data: any) {
        if (!props.onInputChanged) {
            return;
        }

        if (props.type === "number") {
            data = Number(data);
        }
        
        props.onInputChanged({name: props.name, value: data, isValid: Validate(data, props.validationRegex)});
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Input 
                isDisabled={props.disabled}
                value={props.uncontrolled ? undefined : props.value}
                name={props.name}
                type={props.type}
                aria-label={props.label}
                fullWidth
                labelPlacement="outside"
                className="text-input-field"
                classNames={{ label: "text-input-label" }}
                variant="bordered"
                color={valid ? "success" : "danger"}
                errorMessage={hint}
                validationState={valid ? "valid" : "invalid"}
                // isInvalid={inputStatus.hint && !inputStatus.valid}
                onValueChange={props.uncontrolled ? undefined : onValueChange}
                />
        </div>
    )
}

export function InputField(props: InputFieldProps) {
    // const [valid, setValid] = useState<boolean|null>(true);
    const [inputStatus, setInputStatus] = useState<any>({valid: true, color: "default", hint: ""});

    // useEffect(() => {
        // setValid(Validate(props.value, props.validationRegex));
    // }, [props.value, props.validationRegex]);

    // useEffect(() => {
    //     if (!props.onInputChanged) {
    //         return;
    //     }

    //     const value = props.uncontrolled ? "" : props.value;
    //     const valid = Validate(value, props.validationRegex);

    //     props.onInputChanged({
    //         name: props.name,
    //         value: value, 
    //         isValid: valid
    //     });
    // }, []);

    function ValidateInput(inputValue: string | undefined) {
        console.log(props.validationLiteral);
        let value:any = props.uncontrolled ? "" : inputValue;
        // if (inputValue === undefined) {
        //     value = props.uncontrolled ? "" : props.value;
        // } else {
        //     value = inputValue;
        // }
        let isValid; 
        if (props.isValidOverride !== undefined) {
            isValid = props.isValidOverride;
        } else {
            if (props.validationLiteral) {
                isValid = value === props.validationLiteral;
            } else {
                isValid = Validate(value, props.validationRegex);
            }
        }
        // console.log("Validating", value, isValid);

        if (value === "" && props.hideHintWhenEmpty) {
            setInputStatus({...inputStatus, valid: isValid, color: "default", hint: ""});
        } else {
            if (isValid) {
                setInputStatus({...inputStatus, valid: isValid, color: "success", hint: props.validationHint ?? ""});
            } else {
                setInputStatus({...inputStatus, valid: isValid, color: "default", hint: props.validationHint ?? ""});
            }
        }



        // if (props.type === "number") {
        //     value = Number(value);
        // }

        // if (props.onInputChanged) {
        //     props.onInputChanged({
        //         name: props.name,
        //         value: value, 
        //         isValid: isValid
        //     });
        // }

        return isValid;
    }

    useEffect(() => {
        ValidateInput(props.value);
    }, [props.value]);

    // useMemo(() => {
    //     let value = props.uncontrolled ? "" : props.value;
    //     const isValid = Validate(value, props.validationRegex);

    //     if (props.value === "" && props.hideHintWhenEmpty) {
    //         setInputStatus({...inputStatus, valid: isValid, color: "default", hint: ""});
    //     } else {
    //         if (isValid) {
    //             setInputStatus({...inputStatus, valid: isValid, color: "success", hint: props.validationHint ?? ""});
    //         } else {
    //             setInputStatus({...inputStatus, valid: isValid, color: "default", hint: props.validationHint ?? ""});
    //         }
    //     }

    //     if (props.onInputChanged) {
    //         props.onInputChanged({
    //             name: props.name,
    //             value: value, 
    //             isValid: isValid
    //         });
    //     }

    //     // if (!isValid) {
    //         // if (props.value === "" && props.hideHintWhenEmpty) {
    //         //     setInputStatus({...inputStatus, valid: isValid, color: "default", hint: ""});
    //         // } else {
    //         //     setInputStatus({...inputStatus, valid: isValid, color: "default", hint: props.validationHint ?? ""});
    //         // }
    //     // }
    // }, [props.value]);

    // const hint = useMemo(() => {
    //     if (!props.validationHint) {
    //         return "";
    //     }

    //     if (props.hideHintWhenEmpty && props.value === "") {
    //         return "";
    //     }

    //     return props.validationHint;
    // }, [props.value, props.validationHint, props.hideHintWhenEmpty]);

    // const hint = !props.validationHint ? "" : props.validationHint;

    function onValueChange(data: any) {
        if (!props.onInputChanged) {
            return;
        }

        const valid = ValidateInput(data);

        if (props.type === "number") {
            data = Number(data);
        }
        
        // props.onInputChanged({name: props.name, value: data, isValid: Validate(data, props.validationRegex)});
        // console.log("sending blob", {name: props.name, value: data, isValid: valid});
        props.onInputChanged({name: props.name, value: data, isValid: valid});
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Input 
                isDisabled={props.disabled}
                value={props.uncontrolled ? undefined : props.value}
                name={props.name}
                type={props.type}
                aria-label={props.label}
                fullWidth
                labelPlacement="outside"
                className="text-input-field"
                classNames={{ label: "text-input-label" }}
                variant="bordered"
                color={inputStatus.color}
                errorMessage={inputStatus.hint}
                // validationState={inputStatus.valid ? "valid" : "invalid"}
                isInvalid={inputStatus.hint && !inputStatus.valid}
                onValueChange={props.uncontrolled ? undefined : onValueChange}
                />
        </div>
    )
}

export interface TextareaFieldProps extends InputProps {
    tooltip?: string;
    uncontrolled?: boolean;
    minRows?: number;
    maxRows?: number;
    className?: string;
    noAutoSize?: boolean;
    // validation
    validationRegex?: string;
    validationHint?: string;
}

export function TextareaField(props: TextareaFieldProps) {
    const valid = useMemo(() => {
        return Validate(props.value, props.validationRegex);
    }, [props.value]);

    const hint = !props.validationHint ? "" : props.validationHint;

    function onValueChange(data: any) {
        if (!props.onInputChanged) {
            return;
        };
        
        props.onInputChanged({name: props.name, value: data, isValid: Validate(data, props.validationRegex)});
        // setValue(data);
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Textarea 
                isDisabled={props.disabled}
                value={props.uncontrolled ? undefined : props.value}
                name={props.name}
                aria-label={props.label}
                classNames={{ label: "hidden" }}
                spellCheck={false}
                fullWidth
                disableAutosize={props.noAutoSize ? true : false}
                minRows={props.minRows}
                maxRows={props.maxRows}
                labelPlacement="outside"
                className={`text-input-field ${props.className && props.className}`}
                variant="bordered"
                color={!valid ? "danger" : "success"}
                errorMessage={!valid && hint}
                validationState={valid ? "valid" : "invalid"}
                onValueChange={props.uncontrolled ? undefined : onValueChange}
                />
        </div>
    )
}

interface DropdownOption {
    label: string;
    name: string;
}

interface DropdownProps {
    description?: string;
    label: string;
    value: any;
    options: {
        [key: string]: string
    };
    name: string;
    disabled?: boolean;
    disabledOptions?: string[];
    // callbacks
    onInputChanged: Function;
    callbackProps?: any;
}

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem } from "@nextui-org/react";

export function DropdownField(props: DropdownProps) {
    const [selectedValue, setSelectedValue] = useState(props.value);

    useEffect(() => {
        setSelectedValue(props.value);
    }, [props.value]);

    function onValueChange(value: any) {
        props.onInputChanged({name: props.name, value: value, isValid: true});
        setSelectedValue(value);
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Dropdown>
                <DropdownTrigger>
                    <Button variant="bordered" isDisabled={props.disabled} className="flex justify-between" endContent={<FiChevronDown />}>
                        {props.options[selectedValue]}
                    </Button>
                </DropdownTrigger>
                <DropdownMenu 
                    aria-label={`Dropdown ${props.label}`} 
                    disabledKeys={props.disabledOptions}
                    disallowEmptySelection
                    onAction={(value) => onValueChange(value)}
                    selectedKeys={new Set<string>([selectedValue])}
                    selectionMode="single">
                {
                    Object.keys(props.options).map(function(item: any) {
                        return (
                            <DropdownItem key={item} textValue={item}>{props.options[item]}</DropdownItem>
                        )
                    })
                }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

interface DropdownMultiProps {
    label: string;
    dropdownLabel: string;
    value: string[];
    options: {
        [key: string]: string
    };
    disallowEmpty?: boolean;
    name: string;
    disabled?: boolean;
    disabledOptions?: string[];
    // callbacks
    onInputChanged: Function;
    callbackProps?: any;
}

export function DropdownFieldMulti(props: DropdownMultiProps) {
    const [selectedValue, setSelectedValue] = useState<Set<string>>(new Set(props.value));

    function onValueChange(value: any) {
        // props.onInputChanged({name: props.name, value: value, isValid: true});
        // setSelectedValue(value);
        // console.log(value);
        setSelectedValue(value);
        props.onInputChanged({name: props.name, value: value, isValid: true});
    }

    return (
        <div className="flex w-full flex-col">
            <div className="text-input-label">
                <span>{props.label}</span>
            </div>
            <Dropdown>
                <DropdownTrigger>
                    <Button variant="bordered" className="flex justify-between" endContent={<FiChevronDown />}>
                        {props.dropdownLabel}
                    </Button>
                </DropdownTrigger>
                <DropdownMenu 
                    aria-label={`Dropdown ${props.dropdownLabel}`} 
                    disabledKeys={props.disabledOptions}
                    closeOnSelect={false}
                    onSelectionChange={onValueChange}
                    selectedKeys={selectedValue}
                    disallowEmptySelection={props.disallowEmpty}
                    selectionMode="multiple">
                {
                    Object.keys(props.options).map(function(item: any) {
                        return (
                            <DropdownItem key={item}>{props.options[item]}</DropdownItem>
                        )
                    })
                }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
  

interface CheckboxProps {
    description?: string;
    label: string;
    value?: boolean;
    isDefaultSelected?: boolean;
    name: string;
    disabled?: boolean;
    // callbacks
    onInputChanged: Function;
    callbackProps?: any;
}

import {Checkbox} from "@nextui-org/react";

export function CheckboxField(props: CheckboxProps) {
    function onValueChange(data: any) {
        props.onInputChanged({name: props.name, value: data, isValid: true});
    }
    
    return (
        <div className="flex w-full flex-col p-2 rounded hover:bg-content2">
            <Checkbox 
                isDisabled={props.disabled} 
                isSelected={props.value}
                defaultSelected={props.isDefaultSelected}
                // defaultSelected={props.value}
                onValueChange={(value: boolean) => onValueChange(value)}
                size="md"
                classNames={{ base: "w-full max-w-full" }}
                className="flex items-start">
                <div className="w-full">
                    <div className="text-sm font-semibold">{props.label}</div>
                    <div className="text-xs">{props.description}</div>
                </div>
            </Checkbox>
        </div>
    )
}

import RangeSlider from 'react-range-slider-input';
import { InputData } from "@/backend/FormHandlers";

interface RangeSliderProps {
    description: string;
    label: string;
    value: number;
    name: string;
    disabled?: boolean;
    // slider-specific
    min?: number;
    max?: number;
    step?: number;
    unit?: string;
    // callbacks
    onInputChanged: Function;
    callbackProps?: any;
}

export function RangeSliderInput(props: RangeSliderProps) {
    function onValueChange(data: any) {
        props.onInputChanged({name: props.name, value: data[1], isValid: true});
    }

    return (
        <div>
            <div className="flex flex-1 text-input-label justify-between pb-2">
                <div>{props.label}</div>
                <div style={{ color: "hsl(var(--nextui-primary))", fontSize: "14px" }}>{props.value} {props.unit}</div>
            </div>
            <RangeSlider 
                id={props.name} 
                onInput={(value: any) => onValueChange(value)} 
                className="single-thumb" 
                min={props.min} 
                max={props.max} 
                step={props.step} 
                // defaultValue={[props.min, props.value]} 
                value={[props.min, props.value]} 
                rangeSlideDisabled={true} 
                thumbsDisabled={[true, false]} />
            <div>
                <div className="flex justify-between text-input-label pt-2 pb-2">
                    <div>{props.min} {props.unit}</div>
                    <div>{props.max} {props.unit}</div>
                </div>
            </div>
            <div className="text-xs">
                {props.description}
            </div>
        </div>
    )
}

export function FormDivider(props: { label: string }) {
    return (
        <div>
            <div className="text-sm font-semibold p-1 pt-3">{props.label}</div>
            <Divider />
        </div>
    )
}

interface ListInputProps {
    label: string;
    inputFieldLabel: string;
    value: any[];
    outputType: "string" | "number";
    description: string;
    name: string;
    disabled?: boolean;
    // callbacks
    onInputChanged: Function;
    callbackProps?: any;
    maxEntries: number;
    // validation
    validationRegex?: string;
    validationHint?: string;
}

function SortItems(items: string[]) {
    return items.sort(function(a: any, b: any) { return a.toString().localeCompare(b.toString()); });
}

// input to manage lists
export function SimpleSetListInput(props: ListInputProps) {
    const [inputState, setInputState] = useState<InputData>(
        {name: "list_input", value: null, isValid: false}
    );
    const [isInputFieldVisible, setInputFieldVisible] = useState(false);

    // as set
    const sortedSet = new Set<string>(SortItems(props.value));
    const inputDisabled = props.disabled ? true : props.value.length >= props.maxEntries;
    const buttonDisabled = inputDisabled ? true : inputState.value === "" ? true : sortedSet.has(inputState.value) ? true : inputState.isValid ? false : true
    
    function UpdateListInParent() {
        props.onInputChanged({ name: props.name, value: Array.from(sortedSet), isValid: true });
    }

    function OnRemove(key: string) {
        sortedSet.delete(key);
        UpdateListInParent();
    }

    function OnSubmit() {
        sortedSet.add(inputState.value);

        props.onInputChanged({ name: props.name, value: Array.from(sortedSet), isValid: true });
    }

    let rows = [];

    if (sortedSet.size > 0) {
        for (const item of sortedSet) {
            rows.push(
                <div key={item} className="flex bg-primary rounded-xl grow-0 items-center p-1 pl-2 gap-2 simple-list-entry">
                    <div>{item}</div>
                    <Button 
                        className="button-action-tiny-inline bg-primary hover:bg-danger"
                        onPress={() => OnRemove(item)}><FiXCircle /></Button>
                </div>
            )
        }
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-start gap-2 pb-2">
                <div className="w-full">
                    <div className="text-sm font-semibold">{props.label}</div>
                    <div className="text-xs">{props.description}</div>
                </div>
            </div>
            <div className={isInputFieldVisible ? "hidden" : "flex justify-start"}>
                <Button className="button-action" 
                    fullWidth
                    variant="light"
                    color="primary"
                    onPress={() => setInputFieldVisible(true)}
                    startContent={<FiPlusCircle />}>{props.inputFieldLabel}</Button>
            </div>
            <div className={isInputFieldVisible ? "flex flex-row gap-2 pb-2" : "hidden"}>
                <InputField 
                    label={props.inputFieldLabel}
                    validationRegex={props.validationRegex}
                    validationHint={props.validationHint}
                    name="input"
                    disabled={inputDisabled}
                    value={inputState.value ?? ""}
                    onInputChanged={setInputState}
                    />
                <div className="pt-[17px] flex flex-row justify-start items-start gap-2">
                    <Button className="button-action" 
                        onPress={() => OnSubmit()}
                        isDisabled={buttonDisabled} 
                        color={buttonDisabled ? "default" : "success"} startContent={<FiPlusCircle />}>add</Button>
                    <Button className="button-action w-[48px] max-w-[48px] min-w-[48px]"
                        color="primary"
                        onPress={() => setInputFieldVisible(false)}
                        startContent={<FiXCircle size={16} />}></Button>
                </div>
            </div>
            <div className="flex pt-3 pb-2 gap-3 flex-row justify-start items-start flex-wrap">
                {rows}
            </div>
        </div>
    );
}

interface OTPCodeInputProps {
    onValueChanged: Function;
}

export function OTPCodeInput(props: any) {
    const [otpCode, setOtpcode] = useState("");

    function otpValueChanged(value: any) {
        let pattern = new RegExp("^$|^[0-9]+$");

        if (pattern.test(value)) {
            setOtpcode(value);

            if (value.length === 6) {
                props.onValueChanged({code: value, valid: true});
            }
        } else {
            props.onValueChanged({code: value, valid: false});
        }
    }

    return (
        <Input 
            size="lg"
            maxLength={6}
            inputMode="numeric"
            variant="underlined"
            value={otpCode}
            onValueChange={otpValueChanged}
            name="form_otp" 
            labelPlacement="outside"
            className="text-input-field max-h-auto h-[64px]"
            classNames={{ 
                label: "text-input-label", 
                input: "text-[52px] h-[64px] text-center tracking-widest",
                mainWrapper: "h-[64px]"
            }}
            type="text" aria-label="TOTP Code" />
    )
}

export function InputFieldTOTP(props: any) {
    const [otpCode, setOtpcode] = useState("");

    function otpValueChanged(value: any) {
        let pattern = new RegExp("^$|^[0-9]+$");

        if (pattern.test(value)) {
            setOtpcode(value);

            props.onInputChanged({name: props.name, value: value, isValid: value.length === 6});
        } else {
            // props.onValueChanged({code: value, valid: false});
            props.onInputChanged({name: props.name, value: value, isValid: false});
        }
    }

    return (
        <Input 
            size="lg"
            maxLength={6}
            inputMode="numeric"
            variant="underlined"
            value={otpCode}
            onValueChange={otpValueChanged}
            name="form_otp" 
            labelPlacement="outside"
            className="text-input-field max-h-auto h-[64px]"
            classNames={{ 
                label: "text-input-label", 
                input: "text-[52px] h-[64px] text-center tracking-widest",
                mainWrapper: "h-[64px]"
            }}
            type="text" aria-label="TOTP Code" />
    )
}